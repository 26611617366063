@media screen and (max-width:768px) {

    .mainMainAccounPage{
        width: 100%;
    }

    .themenuPage{
        display: none;
    }

    .thenavToHome2{
        display: block;

        color: white;
        text-decoration: none;
        background-color: darkorange;
        padding: 7px 10px;
        border-radius: 5px;
        font-size: 13px;
        font-weight:normal;
    }
    
    .theLogo{
        height: 15px;
    }


    .theTriangle{
        width: 100%; 
        height: 100%;
        background-image: url(./Images/Rectangle1.svg);
        background-size: cover;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 5%;
        padding-right: 10%;
    }


    .theTriangle p{
        font-size: 13px;
    }


    .theTriangle button{
        background-color: white;
        padding: 10px 20px;
        border: 1px solid white;
        color: orangered;
        border-radius: 5px;
        width: fit-content;
        font-weight: bolder;
        font-size: 13px;
    }

    .bottomContactCont{
        display: flex;
        margin-top: 20px;
        flex-direction: column;
    }

    .contactCont{
        display: flex;
        align-items: center;
        margin-left: 0px;
        margin-top: 10px;
    }

    .phoneCont{
        background-color: black;
        color: white;
        padding: 10px;
        border-radius: 50%;
    }

    .callUsCont h5{
        margin: 0;
        padding: 0;
        font-size: 13px;
    }
    
    .callUsCont h6{
        margin: 0;
        padding: 0;
    }
    
    .Hero{
        height: 82vh;   
    }

    .section34{
        display: flex;
        margin-top: 50px;
        margin-bottom: 10px;
        flex-direction: column;
    }
    
    .section34A{
        width: 100%;
        position: static;
    }
    
    .section34B{
        width: 100%;
        margin-right: 5%;
        margin-top: 30px; 
    }

    .sectionImageContBBB{
        position: relative;
    }

    .section34AImage1{
        width: 39%;
        height: 40vh;
        border-radius: 100px; 
        position: static;
        left: 15%;
        margin-left: 20px; 
        background-image: url(./Images/bikers5.jpg);
        background-size: cover;
    }
    
    .section34AImage2{
        width: 39%;
        height: 40vh; 
        background-image: url(./Images/IMG-20120110-00443.jpg);
        background-size: cover;
        border-radius: 100px;
        position: absolute;
        left: 48%;
        top: 8vh;    
    }


    .section34B{
        padding-left: 20px;
        padding-right: 20px; 
    }


    .section34B p{
        font-size: 13px;
    }


    .section35{
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        background-color: #f8f8f8;
        padding-top: 10px;
        padding-left: 0;
        padding-right: 0;
    }
    
    
    .section35A{
        width: 100%;
        margin-top: 40px;
        padding-left: 5%;
        padding-right: 5%;
    }

    .section35B{
        width: 100%; 
        padding-left: 0;
        padding-right: 0;
    }


    .sendRequestCard{
        width: 100%;
        margin-left: 0%;
        height: 85vh;
        background-color: #020E28;
        border-radius: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        overflow: scroll; 
    }

    .section36{
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 30px;
        clear:both;
        margin-bottom: 100px;
    }
     
    .section36A{
        width:fit-content;
        overflow: hidden;
        width: 100%;
        margin-top: 5vh;
        float: left; 
        margin-right: 20px; 
        margin-left: 0px;
    }
    
    .Operations{
        width: 100%;
        clear: both;
        margin-top: 30px;
        background-color: #020E28;
        color: white;
        padding: 20px;  
    }

    .services1 img{
        width: 20%;
        padding: 70px; 
    }

    .theTeam{
        height: 40vh;
        width: 45%; 
        border: 1px solid gray;
        border-radius: 5px;
        float: left;
        margin-right: 1px;
        margin-left: 10px;
        margin-bottom: 10px;
    } 

    .ourTeam{
        padding-left: 0%;
        padding-right: 0%;
        margin-bottom: 100px;
        clear: both;
    }

    .theTeam p{
        padding: 0 0 0 10px;
        margin: 0;
        font-size: 13px;
    }
    
    .theTeam h5{
        padding: 10px 0 0 10px;
        margin: 0;
        font-size: 13px;
    }


    .newContact{
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
    
    
    .newContactOne{
        width: 100%;
        height: 100%;
    }
    
    .newContactTwo{
        width: 100%;
        height: 100%;
        background-color: #020E28;
        padding: 20px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    

}