.homePage{
    height: 100vh;
    width: 100%;
    background-image: url(./Images/pexels-antony-trivet-13348192\ \(3\).jpg);
    background-size: cover;
}


.navBar{
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 3vh;
}

.theNavBar{
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4vh;
    padding-bottom: 2vh;
}


.menuPage li{
    display: inline;
    margin-left: 30px;
}


.themenuPage li{
    display: inline;
    margin-left: 30px;
}




.homeContent{
    margin-left: 5%;
    margin-top: 25vh;
    width: 40%;
}

.homeContent h1{
    font-weight: bolder;
    color: white;
    font-size: 60px;

}



.homeContent button{
    background-color: orange;
    padding: 7px 20px;
    color: black;
    border: 1px solid white;
    border-radius: 5px;
}

.menuPage a{
    color: white;
    text-decoration: none;
}

.themenuPage a{
    /* color: white; */
    text-decoration: none;
}

.themenuPage a{
    /* color: white; */
    text-decoration: none;

}

.menuItems{
    font-weight: bold;
    color: black;
}

.navToHome{
    color: black;
    text-decoration: none;
    background-color: orange;
    padding: 15px 35px;
    border-radius: 20px;
    font-size: 13px;
}


.thenavToHome{
    color: white;
    text-decoration: none;
    background-color: darkorange;
    padding: 15px 35px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: bold;
}

.thenavToHome2{
    color: white;
    text-decoration: none;
    background-color: darkorange;
    padding: 15px 35px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: bold;
    display: none;
}


.section2{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.section2Cont{
    display: flex;
    margin-top: 70px;
    align-items: top;
} 

.box1Home{
    width: 30%;
    height: 50vh;

    margin-left: 20px;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: red;
    font-size: 13px;
}

.box1Home h5{
    margin-bottom: 20px;
    margin-top: 10px;
}

.aboutHome{
    display: flex;
}

.aboutHome1{
    width: 50%;
    height: 70vh;
    background-image: url(./Images//african-women-entrepreneur.webp);
    background-size: cover;
}

.aboutHome2{
    width: 50%;
    height: 70vh; 
    padding: 0px 20px 0px 20px;
}

.aboutHome2Child{
    border: 1px double gray; 
    height: 100%;
    width: 100%;
    border-radius: 5px;
    padding: 20px;

}

.services{
    margin: 10%;
}

.services1{
    display: flex;
    align-items: center;
}

.services1 img{
    width: 45%;
    padding: 70px;
}


.contactMapLocation iframe{
    width: 100%;
    height: 75vh;
}


.footerHome{
    background-color: royalblue;
    height: 50vh;
    margin-top: -10px;
}

.footerCont{
    display: flex;
    color: white;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 20vh;
    text-align: center;

}

.copyRightCont{
    text-align: center;
    width: 100%;
    background-color: royalblue;
    color: white;
    padding-bottom: 5vh;
}



.contactInfo{
    font-size: 70px;
}

.navToHome{
    color: black;
    text-decoration: none;
    background-color: darkorange;
    padding: 10px 25px;
    border-radius: 20px;
}


/* new stuff */


.Hero{
    height: 90vh;
    width: 100%;
    background-image: url(./Images/pexels-thirdman-6958489.jpg);
    background-size: cover;
    background-position: right; 
}

.theTriangle{
    width: 60%;
    height: 100%;
    background-image: url(./Images/Rectangle1.svg);
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 10%;
}



.theTriangle button{
    background-color: white;
    padding: 15px 40px;
    border: 1px solid white;
    color: orangered;
    border-radius: 5px;
    width: fit-content;
    font-weight: bolder;
}


.bottomContactCont{
    display: flex;
    margin-top: 20px;
}


.theTriangle h1{
    color: white;
    font-weight: bolder;
}


.contactCont{
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.phoneCont{
    background-color: black;
    color: white;
    padding: 20px;
    border-radius: 50%;
}


.callUsCont{
    margin-left: 20px;
}


.callUsCont h5{
    margin: 0;
    padding: 0;
}

.callUsCont h6{
    margin: 0;
    padding: 0;
}


.section34{
    display: flex;
    margin-top: 100px;
    margin-bottom: 100px;
}


.section34A{
    width: 50%;
    position: relative;
}

.section34B{
    width: 50%;
    margin-right: 5%;
}

.section34B p{
    color: gray;
    font-weight: 500;
}


.section34B h1{
    font-weight: bolder;
    color: orangered;
}


.section34AImage1{
    width: 40%;
    height: 70vh;
    border-radius: 150px;
    position: absolute;
    left: 15%;
    background-image: url(./Images/bikers5.jpg);
    background-size: cover;
}

.section34AImage2{
    width: 40%;
    height: 70vh;
    background-image: url(./Images/IMG-20120110-00443.jpg);
    background-size: cover;
    border-radius: 150px;
    position: absolute;
    left: 45%;
    top: 20vh;
}


.section35{
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 100px;
    background-color: #f8f8f8;
    padding-top: 100px;
}


.section35A{
    width: 50%;
    margin-top: 50px;

}

.section35A h1{
    font-weight: bolder;
    color: orangered;
}


.section35A h5{
    color: royalblue;
}

.section35A li{
    color: rgb(101, 98, 98);
    font-weight: 500;
}


.section35B{
    width: 50%;
}

.sendRequestCard{
    width: 90%;
    margin-left: 10%;
    height: 85vh;
    background-color: #020E28;
    border-radius: 5px 100px 5px 5px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 50px;
    overflow: scroll;
}

.sendRequestCard label{
    color: white;
}


.sendRequestCard button{
    background-color: orange;
    color: white;
    padding: 15px 40px;
    border: none;
}


.section36{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 100px;
    clear:both;
    margin-bottom: 100px;
}


.section36A{
    width:fit-content;
    overflow: hidden;
    width: 30%;
    margin-top: 5vh;

    float: left; 
    margin-right: 20px; 
    margin-left: 20px;
}

.section36 h1{
    font-weight: bolder;
    color: orangered;
}


.section36ATop{
    background-image: url(./Images/bikers5.jpg);
    height: 35vh;
    width: 100%;
    background-size: cover;
    border-radius: 5px;
    background-position: center;
}

.section36BTop{
    background-image: url(./Images/IMG-20120110-00443.jpg);
    height: 35vh;
    width: 100%;
    background-size: cover;
    border-radius: 5px;
    background-position: center;
}

.section36CTop{
    background-image: url(./Images/bikers2.jpg);
    height: 35vh;
    width: 100%;
    background-size: cover;
    border-radius: 5px;
    background-position: center;
}

.section36DTop{
    background-image: url(./Images/caravan1.jpg);
    height: 35vh;
    width: 100%;
    background-size: cover;
    border-radius: 5px;
    background-position: center;
}

.section36ETop{
    background-image: url(./Images/kbl\ van1.jpg);
    height: 35vh;
    width: 100%;
    background-size: cover;
    border-radius: 5px;
    background-position: center;
}

.section36FTop{
    background-image: url(./Images/kbb\ trck3.jpg);
    height: 35vh;
    width: 100%;
    background-size: cover;
    border-radius: 5px;
    background-position: center;
}


.section36A p{
    font-size: 13px;
    color: rgb(101, 98, 98);
    font-weight: 500;
}

.section36A h5{
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 900;
    
}


.Operations{
    width: 100%;
    clear: both;
    margin-top: 100px;
    background-color: #020E28;
    color: white;
    padding: 100px;
}


.theTeam{
    height: 40vh;
    width: 23%;
    border: 1px solid gray;
    border-radius: 5px;
    float: left;
    margin-right: 25px;
}

.theTeamTop{
    background-image: url(./Images/avatar-3814081_1280.webp);
    height: 30vh;
    width: 100%;
    background-size: cover;
    background-position: center;
}


.theTeam p{
    padding: 0 0 0 10px;
    margin: 0;
}

.theTeam h5{
    padding: 10px 0 0 10px;
    margin: 0;
}


.ourTeam{
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 100px;
    clear: both;
}


.clearFix{
    clear: both;
    margin-bottom: 100px;
}


.newContact{
    height: 100vh;
    display: flex;
}


.newContactOne{
    width: 50%;
    height: 100%;
}

.newContactTwo{
    width: 50%;
    height: 100%;
    background-color: #020E28;
    padding: 100px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.newContactOne iframe{
    width: 100%;
    height: 100%;
}

.h1Out{
    margin-left: 5%;
    margin-bottom: 20px;
}



.socialMediaIcons img{
    cursor: pointer;
    margin-right: 10px;
}

.pickupDivsc{
    background-color: white;
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
}


.signatureName{
    height: 150px;
}