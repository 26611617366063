.navbar{
    height: 10vh;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: end;
}

.prof{
    height: 50px;
    width: 50px;
    border: 1px solid white;
    border-radius: 5px;
}


.notif{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}



.prof img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}



.navbar p{
    margin-left: 10px;
    width: 50%;
    line-height: 17px;
    margin-top: 14px;
    
}