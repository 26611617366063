.leftTop{
    position: absolute;
    width: 30%;
    height: 60vh;
    background: #FFF7E2;;
    border-radius: 0px 0px 50px 0px;
    transform: rotate(45deg);
    left: -8%;
    top: -31vh;
    z-index: 10;
}



.contentMain{
    width: 100%;
    height: 90vh;
    display: flex;
 
}


.theRightOne{
    width: 80%;
    height: 84vh;
    padding-left: 3%;
    padding-right: 3%;
}


.DashTop{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;

}


.dataComp{
    background-color: #F5F5F5;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow: auto;
}

.dataComp1{
    background-color: #F5F5F5;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    height: 85%;
    width: 100%;
    padding: 20px;
    overflow: auto;
}


.fixed_header tbody{
    display:block;
    overflow:auto;
    height:200px;
    width:100%;
  }
  .fixed_header thead tr{
    display:block;
  }

.tableBody{
    overflow: scroll;
}

.dataCompTop{
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}


.searchContainer button{
    background-color: #d58f37;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    margin-left: 10px;
}


.dataCompTop input{
    border: 1px solid #d58f37;
    border-radius: 5px;
    padding: 10px 0px 10px 40px;
    font-size: 13px;
    width: 300px;   
}

.dataCompTop:focus{
    border: #d58f37 1px solid;
}

.searchContainer{
    position: relative;
    padding-right: 20px;
}

.searchIcon{
    position: absolute;
    left: 15px;
    color: gray;
    top: 15px;

}



.addNewDevice{

    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #d58f37;
    color: white;
    position: absolute;
    bottom: 20px;
    right: 20px;
}


.bottomMenu{
    bottom: 4%;
    right: 5%;
    display: flex;
    position: fixed;
}

.addNewMenu{
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #d58f37;
    color: white;

}

.uploadMenu{
    border: 1px #d58f37 solid;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    color: gray;
    margin-right: 10px;


}

.mymenu{
    display: flex;
    align-items: center;
    padding-left: 20px;
}


.wineBtn{
    border-radius: 20px;
    padding: 7px 30px;
    font-size: 13px;
    margin-right: 10px;
    background-color: #d58f37;
    border: #d58f37 1px solid;
    color: white;
    margin-left: 20px;
}


.foodBtn{
    background-color: transparent;
    border: gray 1px solid;
    color: gray;
    border-radius: 30px;
    padding: 7px 20px;
    font-size: 13px;
    margin-right: 10px;
}


.wineBtn1{
    border-radius: 20px;
    padding: 7px 30px;
    font-size: 13px;
    /* margin-right: 10px; */
    background-color: #d58f37;
    border: #d58f37 1px solid;
    color: white;
    margin-left: 10px;
}


.foodBtn1{
    background-color: transparent;
    border: gray 1px solid;
    color: gray;
    border-radius: 30px;
    padding: 7px 20px;
    font-size: 13px;
    /* margin-right: 10px; */
    margin-left: 10px;
}

.wineBtn2{
    border-radius: 20px;
    padding: 7px 30px;
    font-size: 13px;
    margin-right: 10px;
    background-color: transparent;
    border: gray 1px solid;
    color: gray;
    margin-left: 20px;
    margin-right: 20px;
}

.foodBtn2{
    background-color: #d58f37;
    border: #d58f37 1px solid;
    color: white;
    border-radius: 30px;
    padding: 7px 20px;
    margin-right: 20px;
    font-size: 13px;
}


.topAdminDash{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7vh;

}


.currentUsers{
    background-color: #d58f37;
    width: fit-content;
    color: white;
    padding: 15px 50px;
    border-radius: 5px;

    border: 1px solid #d58f37;
}


.currentUsers p{
    margin: 0;
}


.currentUsers h2{
    margin: 0;
}



.timeSpent{
    border: gray;
    color: gray;
    border: 1px solid gray;
    padding:  15px 50px;
    border-radius: 5px;
}

.timeSpent p{
    margin: 0;
}


.timeSpent h2{
    margin: 0;
}


.searchAdmin{
    padding-top: 10px;
}


.searchContainerAdmin{
    display: flex;
    display: flex;
    width: 40%;
    position: relative;
}


.searchContainerAdmin input{
    padding: 7px 30px;
    border-radius: 5px;
    border: gray 1px solid;
    margin-right: 10px;
    width: 100%;


}

.searchContainerAdmin button{
    background-color: #d58f37;
    color: white;
    border: 1px solid #d58f37;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
}


.theSearchIconAdmin{
    position: absolute;
    color: gray;
    margin-left: 10px;
    margin-top: 12px;
}


.myTableBody{
    font-size: 13px;
}

.myTableBody button{
    font-size: 13px;
}


.timeSpent span{
    font-size: 15px;
}


.tbody{
font-size: 10px;
}


.logWidth1{
    width: 20%;
}

.logWidth2{
    width: 20%;
}


.logisticsPricing{
    width: 31%;
    border: grey 1px solid;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
    float: left;
    margin-right: 20px;
}

.logisticsPricing p{
    font-size: 14px;
}

.pricingSpan{
    font-size: 18px;
}

.pricongP{
    font-size: 13px;
    margin-bottom: 0;
}


.checkIcon{
    color: green;
    font-size: 12px;
}

.logisticsPricing button{
    background-color: #d58f37;
    color: white;
    border: none;
    font-size: 12px;
    padding: 7px 20px;
    margin-top: 20px;
    border-radius: 20px;
}



td{
    font-size: 13px;
}



.dashAnalysis{
    height: 250px;
    width: 48%;
    border-radius: 5px;
    padding: 20px;
    color: white;
    float: left;
    margin-right: 4%;
    
}


.dashAnalysis2{
    height: 250px;
    width: 48%;
    border-radius: 5px;
    padding: 20px;
    color: white;
    float: left;
    
}


.dashAnalysis p{
    margin: 0;
    padding: 0;
}

.dashAnalysis2 p{
    margin: 0;
    padding: 0;
}


.viewOrderComp{
    height: 100%;
    width: 100%;
    display: flex;
}



.viewLeft{
    height: 100%;
    width: 70%;
    padding-right: 20px;
}


.viewLeft p{
    margin: 0;
    padding: 0;
    font-size: 13px;
}

.viewRight{
    width: 30%;
    height: 100%;
    background-color: #d58f37;
    padding: 20px;
}


.viewProfCont{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;

}

.roundProfileView{
    height: 120px;
    width: 120px;
    background-color: white;
    border-radius: 50%; 
    overflow: hidden;
}


.roundProfileView img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    
}


.roundH5{
    color: white;
    margin-top: 20px;
}


.viewProfCont button{
    background-color: white;
    border: 1px solid black;
    padding: 7px 20px;
    font-size: 13px;
    margin-left: 2px;
    margin-right: 2px;
}


.waybillsCont{
    display: flex;
    justify-content: space-between;
    align-items: center;
}



/* waybills */


.myPDFReceipt{
    margin: 20px;
    height: fit-content;
    width: 1000px;
    border: 1px black solid;
    padding:20px 5px 5px 5px ;
}

.myPDFReceiptHead{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.myPDFReceiptHead p{
    margin: 0;
    padding: 0;
}

.myPDFReceiptHead h2{
    margin: 0;
    padding: 0;
}

.detailPDFCont{
    border: 1px black solid;
    margin-top: 20px;
    height: fit-content;
    display: flex;
    width: 100%;
}

.detailPDFCont h5{
    margin: 0px 0px 10px 0px;
    padding: 0;
}

.detailPDFCont hr{
    border: 1px solid black;
    border-bottom: none;
}

.detailPDFLeft{
    width: 50%;
    height: 100%;
    border-right: 1px solid black;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
 
}

.detailPDFRight{
    width: 50%;
    height: 100%;
    padding: 20px 20px 0px 20px;
}

.detailPDFCont p{
    font-size: 13px;
    margin: 0;
    padding: 0;
}

.myPDFReceiptImageCont{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.myPDFReceiptImageCont p{
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.myPDFReceiptImageCont img{
    height: 5vh;
    margin-right: 5%;
}

.transactionRef{
    color: red;
    font-size: 16px;
    font-weight: bold;
}

.expbtn{
    margin-left: 20px;
}