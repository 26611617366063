.leftbar{
    height: 100vh;
    width: 20%;
    background-color: #d58f37;
    z-index: 12;
    padding-left: 35px;
    padding-right: 35px;
    
    display: flex;
   justify-content: space-between;
   flex-direction: column;
   font-size: 13px;
   padding-top: 5vh;
   padding-bottom: 5vh;


}

.logocontainer{
    margin-bottom: 5vh;
    color: white;
}

.logo{
    height: 8vh;
    margin-left: 10px;
}




.leftLinkCont{
    text-decoration: none;
}


.leftNavLinks{
    text-decoration: none;
    color: white;
    padding: 10px 15px;
    width: 100%;
}

.leftNavLinks:hover{
    background-color: white;
    border-radius: 7px;
    color: #d58f37;
}




.laftNavIcon{
    margin-right: 10px;
}


.active{
    background-color: white;
    border-radius: 7px;
    color: #d58f37;
}


.logoutbtn{
    cursor: pointer;
}