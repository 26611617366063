.mainAuth{
    height: 100vh;
    width: 100%;
    display: flex;
}




.AuthLeft{
    height: 100vh;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.AuthRight{
    height: 100vh;
    width: 40%;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
}



.signuptext{
    text-align: center;
    font-size: 13px;
    margin-top: 20px;
    color: #00855f;
    text-decoration: none;

}

.signuptext:hover{
    color: #0f6a50;
}


.btnLogin{
    margin-top: 40px;
    background-color: #d58f37;
    border: #d58f37 1px solid;
}

.btnLogin:hover{
    background-color: #840d15;
    border: 1px solid #840d15;
}


.authleftCont{
    height: 96%;
    width: 96%;
    background-color: white;
    border-radius: 0px 20px 0px 20px;
    overflow: hidden;
    position: relative;
}


.logoAuth{
    height: 50px;
    width: max-content;
    margin-bottom: 50px;
}

.authleftCont video{
    height: 100%;
    width: 100%;
    object-fit: cover;
}



.authLeftOpacity{
    height: 100%;
    width: 100%;
    background-color: black;
    position: absolute;
    top: 0;
    opacity: 0.2;
}